.content-wrapper {
  position: relative;
  display: flex;
  justify-content: center;
}

.sponsor-table {
  display: flex;
  justify-content: center;
}

.table-section-wrapper {
  margin: 0 4%;
  width: 100%;
}

@media (min-width: 1440px) {
  .table-section-wrapper {
    max-width: 1440px;
  }
}

.sponsor-table-wrapper {
  float: right;
  width: 50%;
  margin-left: 20px;
}

section {
  overflow: hidden;
}

p.center {
  text-align: center;
}

@media screen and (max-width: 1024px) {
  .sponsor-table-wrapper {
    float: none;
    width: 100%;
    margin-left: 0;
    margin-bottom: 20px;
  }
}

/* .sponsor-container {
  margin-top: 5px;
    margin-left: 30px;
    margin-bottom: 50px;
} */

ul.past-websites {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  justify-items: center;
  gap: 16px;
  list-style-type: none;
  padding: 0;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.past-thumbnail {
  width: 100%;
  min-width: 150px; /*For phone screens */
  aspect-ratio: 295 / 209;
  max-width: 295px;
  border-radius: 10px;
  margin-bottom: 20px;
  transition: all 0.2s;
  margin: 0;
}

.past-thumbnail:hover {
  scale: 0.95;
}

@media (max-width: 600px) {
  .past-thumbnail {
    object-fit: cover;
  }

  ul.past-websites {
    grid-template-columns: repeat(2, 1fr);
    gap: 10px;
  }
}

p {
  line-height: 150%;
}

.color1 {
  color: #180161;
}

.color2 {
  color: #4f1787;
}

.color3 {
  color: #eb3678;
}

.color4 {
  color: #fb773c;
}

.all {
  background-color: white;
}

.general {
  color: black;
}

.recruiting {
  color: black;
}

.branding {
  color: black;
}

.swag {
  color: black;
}

.sponsorship-doc {
  display: flex;
  flex-direction: column;
  padding-left: 4%;
  padding-right: 4%;

  font-family: "Work Sans", Helvetica, Arial, sans-serif;
  color: black;
}

/* mobile devices, column */
@media (max-width: 1200px) {
  #sponsorship-doc {
    flex-direction: column;
  }
}

.sponsorship-info {
  width: 50%;
  display: "inline";
  padding-right: "5%";
}

@media (max-width: 1200px) {
  .sponsorship-info {
    width: 100%;
  }
}

#background-paw {
  position: absolute;
  z-index: -1;
  top: -15vw;
  right: -10vw;
  width: 50vw;
  transform: rotate(15deg);
  filter: contrast(0) brightness(1.95);
}

.header {
  text-align: left;
  padding-bottom: 1%;
  padding-left: 4%;
  padding-right: 4%;
  padding-top: 2%;

  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  color: #0e0532;
}

.header .title-bar {
  font-size: 48px;
  display: flex;
  gap: 8px;
  align-items: center;
  margin-bottom: 20px;
}

.title-bar img {
  width: 66px;
  height: 66px;
  border-radius: 10px;
  margin-right: 20px;
}

hr {
  border: 0;
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
}

h1 {
  font-family: "Azeret Mono";
  font-size: 48px;
  font-weight: 400;
  line-height: 150%;
}

h2 {
  font-family: "Azeret Mono";
  font-size: 32px;
  font-weight: 500;
  line-height: 150%;
  color: rgba(53, 9, 123, 1);
}

.color-wrapper h2 {
  color: white;
}

h3 {
  font-family: "Azeret Mono";
  font-size: 24px;
  font-weight: 500;
  line-height: 150%;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  line-height: 1.4; /* Adjust to match the mockup */
}

p {
  line-height: 1.6; /* Adjust for paragraph text */
}

.header p {
  font-size: 1em;
  color: #b3b3b3;
  margin-top: 5px;
}

.content {
  flex: 0 0 66.66%;
  max-width: 66.66%;
}

.content p {
  font-size: 0.9em;
  line-height: 1.6;
  margin-bottom: 15px;
  color: black;
}

.content h4 {
  font-size: 1.1em;
  margin-top: 15px;
  color: #00a6ce;
}

.content ul {
  padding-left: 20px;
  list-style-type: disc;
}

.content ul li {
  margin-bottom: 10px;
}

.sidebar {
  flex: 0 0 33.33%;
  max-width: 33.33%;
  padding-left: 20px;
  text-align: left;
}

.sidebar h2 {
  font-size: 1.5em;
  color: black;
  margin-bottom: 10px;
}

.sidebar a {
  font-size: 1.1em;
  color: #e74c3c;
  text-decoration: none;
  font-weight: bold;
}

.sidebar p {
  font-size: 0.9em;
  color: #555;
  margin-top: 10px;
}

.calendly-link {
  color: rgba(53, 9, 123, 1);
  font-weight: 600;
  text-decoration: underline;
}

.email-link {
  color: #eb3678;
  text-decoration: none;
}

#sponsorship-tiers {
  width: 45%;
}

@media (max-width: 1200px) {
  #sponsorship-tiers {
    width: 100%;
  }
}

#sponsorship-tiers h2 {
  color: rgba(53, 9, 123, 1);
  font-size: 10;
  margin-bottom: 5%;
  margin-left: 6%;
  text-align: left;
  width: 80%;
}

#section-header h2 {
  color: rgba(53, 9, 123, 1);
  font-size: 10;
  margin-top: 10%;
  /* padding-top: 20%; */
  /* margin-bottom: 30px; */
  /* margin-left: 8%; */
  text-align: left;
  width: 80%;
}

.table-responsive {
  width: 100%;
  overflow-x: auto; /* Enable horizontal scrolling */
}

.table {
  background-color: white;
  border-radius: 15px;
  width: 100%;
  margin-bottom: 5rem;
  color: #212529;
  border-collapse: collapse; /* Changed from separate to collapse*/
  border-spacing: 0;
}

.table th,
.table td {
  padding: 16px 24px;
  text-align: center;
  vertical-align: top;
  font-size: 20px;
  border: 0.25px solid rgba(0, 0, 0, 0.1);
}
.table td {
  font-family: "Work Sans";
}

.table th {
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  border-top: none;
  font-family: Azeret Mono;
  font-size: 20px;
  font-weight: 400;
  text-align: center;
}

.table th:first-child {
  border-left: none;
}

.table th:last-child {
  border-right: none;
}

.table tr td:first-child {
  text-align: left;
}

.table th .name {
  font-size: 1rem;
  font-weight: 400;
}

.table th .amount {
  font-size: 1.3rem;
  font-weight: bold;
}

.table thead th {
  vertical-align: bottom;
  font-size: 1rem;
}

.table tbody tr:first-child td {
  color: rgba(75, 144, 255, 1);
  font-family: Azeret Mono;
  border-top: 0;
  background-color: rgba(75, 144, 255, 0.1);
}

.table td.check {
  position: relative;
}

.table td.check i {
  font-size: 1.2em;
}

.table .amount {
  font-size: 1rem;
  font-weight: normal;
  display: block;
  margin-top: 0.5rem;
  color: black;
}

@media (max-width: 768px) {
  .table th,
  .table td {
    padding: 12px 8px; /* Reduce padding on small screens */
    font-size: 14px; /* Adjust font size */
  }

  .table th .name,
  .table th .amount {
    font-size: 14px;
  }
}

.calendly-button-container {
  margin-top: 0px;
  text-align: center;
  margin-bottom: 0px;
}

.calendly-button {
  display: inline-block;
  padding: 15px 30px;
  background-color: #180161;
  color: white;
  text-align: center;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  border-radius: 15px;
  transition: all 0.2s ease;
}

.calendly-button:hover {
  background-color: #36217c;
  transform: scale(1.05);
}

.padded-text {
  padding-top: 20px;
  padding-bottom: 40px;
}

.underline-link {
  text-decoration: none;
  color: inherit;
}

.underline-link:hover {
  color: inherit;
}

.image-container {
  text-align: center;
  margin-top: 40px;
  margin-bottom: 5px;
}

.hackatbrown-image {
  width: 80%;
  max-width: 600px;
  height: auto;
  border-radius: 10px;
}

#paw {
  position: relative;
  z-index: 0;
  top: 0.2em;
  transform: rotate(15deg);
  filter: brightness(0.46);
}

#small-paw {
  position: relative;
  z-index: 0;
  right: -0.5em;
  transform: rotate(15deg);
  filter: brightness(5);
  height: 1em;
  top: 0.1em;
}

/* navbar */

.navbar {
  position: fixed;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 8px 4%;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  width: calc(100vw - 8%);
  background-color: rgba(53, 9, 123, 1);
  color: white;

  animation: header-down 0.5s;
}

.navbar .button.outline {
  background-color: rgba(53, 9, 123, 1);
  color: white;
  border: 1px solid white;
  border-radius: 8px;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.navbar .button.outline:hover {
  background-color: white;
  color: rgba(53, 9, 123, 1);
}

.navbar .button.filled {
  background-color: rgb(89, 138, 237);
  color: #ffffff;
  border: 1px solid rgb(89, 138, 237);
  border-radius: 8px;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.2s ease;
  cursor: pointer;
  font-family: Work Sans;
  font-size: 16px;
  font-weight: 500;
  line-height: 28px;
  text-align: left;
}

.navbar .button.filled:hover {
  filter: brightness(1.25);
}

@media (max-width: 1100px) {
  .navbar {
    justify-content: center;
    flex-direction: column;
  }

  .navbar > div {
    display: none;
  }
}

@media (max-width: 500px) {
  .navbar .button.outline,
  .navbar .button.filled {
    padding: 0.25rem 0.75rem; /* Adjusted padding */
    font-size: 14px; /* Adjust font size to Figma spec */
  }

  .button {
    font-size: 14px;
    padding: 8px 16px;
    width: 100%;
  }

  .navbar .message {
    font-size: 12px;
    padding: 0;
  }

  .button.outline {
    font-size: 12px;
    padding: 6px 12px;
  }
  .button.filled {
    font-size: 12px;
    padding: 6px 12px;
  }
}

/* who-section */

.who-section {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  gap: 16px;
}

.who-section > div {
  width: 50%;
  height: min-content;
}

/* smaller screens */
@media (max-width: 1024px) {
  .who-section {
    flex-direction: column;
  }
  .who-section > div {
    width: 100%;
  }
}

/* stat bar */

@media (max-width: 600px) {
  .stat-bar {
    display: grid !important;
    grid-template-columns: repeat(2, 1fr);
  }
}

.stat-bar {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  background-color: rgba(75, 144, 255, 1);
  color: white;
  text-align: center;
  padding: 50px 0px;
  margin: 50px 0;
  flex-wrap: wrap;
  gap: 50px;
}

.stat-bar .stat h3 {
  font-size: 48px;
  font-weight: 500;
  line-height: 72px;
  margin: 0;
}

.stat-bar .stat p {
  font-family: "Work Sans";
  font-weight: 500;
  font-size: 16px;
  margin: 0;
}

/* why-list */

ul.why-list {
  list-style-type: none;
  padding: 0;
}

.why-list li {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  margin: 50px 0;
}

.why-list li > h3,
.why-list li > p {
  width: 50%;
}

.why-list li > h3 > div {
  font-size: 20px;
  font-weight: 400;
  margin: 0 0 1em 0;
}

.fa-check {
  color: rgba(75, 144, 255, 1);
}

.why-list li > h3 {
  margin: 0;
}

@media (max-width: 1024px) {
  .why-list li {
    flex-direction: column;
  }
  .why-list li > h3,
  .why-list li > p {
    width: 100%;
  }
}

.color-wrapper {
  background-color: rgba(75, 144, 255, 1);
}

.color-wrapper h2 {
  color: white;
  padding: 30px 0 15px 0;
}

/* meet */

.meet {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 50px 0;
  gap: 1em;
}

.meet div {
  width: 50%;
}

.meet img {
  border-radius: 16px;
  width: 100%;
}

.meet p {
  font-size: 1.25em;
}

@media (max-width: 1024px) {
  .meet p {
    text-align: center;
  }

  .meet div {
    width: 100%;
  }

  .meet {
    flex-direction: column;
  }

  .meet img {
    display: none;
  }
}

/* footer */

footer {
  background-image: url("../assets/footer.jpeg");
  background-size: cover;
  background-position: center;

  height: 100vh;
}

footer img {
  position: relative;
  left: calc(100vw - 50px);
  top: calc(100vh - 50px);
  opacity: 0.5;
  filter: brightness(2);
}

/* animations */

@keyframes header-down {
  0% {
    transform: translateY(-100%);
    opacity: 0;
  }
  50% {
    transform: translateY(-100%);
    opacity: 0;
  }
  100% {
    transform: translateY(0);
    opacity: 1;
  }
}

ul.past-websites li:nth-child(1) {
  scale: 0.75;
  opacity: 0;
  animation: past-websites 0.5s 0.05s forwards ease;
}

ul.past-websites li:nth-child(2) {
  scale: 0.75;
  opacity: 0;
  animation: past-websites 0.5s 0.1s forwards ease;
}

ul.past-websites li:nth-child(3) {
  scale: 0.75;
  opacity: 0;
  animation: past-websites 0.5s 0.15s forwards ease;
}

ul.past-websites li:nth-child(4) {
  scale: 0.75;
  opacity: 0;
  animation: past-websites 0.5s 0.2s forwards ease;
}

@keyframes past-websites {
  0% {
    scale: 0.75;
    opacity: 0;
  }
  100% {
    scale: 1;
    opacity: 1;
  }
}

/* Additional styles for screens < 500px */
@media (max-width: 500px) {
  .navbar .button.outline,
  .navbar .button.filled {
    font-size: 12px;
    padding: 8px 12px;
  }

  .navbar .message {
    font-size: 12px;
    padding: 0;
  }

  .who-section {
    flex-direction: column;
  }

  .navbar .button.outline,
  .navbar .button.filled {
    padding: 0.25rem 0.75rem; /* Adjusted padding */
    font-size: 14px; /* Figma font size */
    width: 100%;
  }

  /* .who-section > div {
    width: 100%;
    text-align: center;
    padding-left: 10px;
    padding-right: 10px;
  } */

  .meet img {
    width: 100%; /* Full width for images */
  }

  .sponsorship-doc {
    padding-left: 5%;
    padding-right: 5%;
  }

  .table th,
  .table td {
    padding: 8px 4px; /* Reduce padding on small screens */
    font-size: 14px;
  }

  .table {
    margin-bottom: 3rem; /* Adjust margin to avoid overflow */
  }

  .stat-bar {
    flex-direction: column;
    padding: 30px 0; /* Adjust padding */
  }

  .stat-bar .stat h3 {
    font-size: 36px;
    line-height: 50px;
  }

  .stat-bar .stat p {
    font-size: 14px;
  }

  .why-list li {
    margin: 30px 0; /* Adjust spacing */
  }

  .header {
    text-align: center;
    padding-left: 2%;
    padding-right: 2%;
  }

  .header .title-bar {
    font-size: 10px; /* Reduced title size */
    padding-left: 2%;
    padding-right: 2%;
  }

  .header .title-bar img {
    width: 40px;
    height: 40px;
    margin-right: 10px;
  }

  .calendly-button {
    padding: 10px 20px; /* Adjust button padding */
  }

  .content p {
    padding-left: 5%;
    padding-right: 5%; /* Add padding to text content */
  }
}

@media (max-width: 400px) {
  .header {
    text-align: center;
    padding-left: 1%;
    padding-right: 0%;
  }

  .header .title-bar {
    padding-left: 2%;
    padding-right: 2%;
  }

  .header .title-bar img {
    display: none;
    width: 40px;
    height: 40px;
    margin-right: 6px;
  }
}

@media (min-width: 1440px) {
  .sponsorship-doc {
    max-width: 1440px;
    margin: 0 auto;
  }
}

.sponsor-table img {
  width: 100%;
  margin-bottom: 60px;
}
